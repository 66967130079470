export const USER_POOLS_REQUEST = 'USER_POOLS_REQUEST'
export const USER_POOLS_SUCCESS = 'USER_POOLS_SUCCESS'
export const USER_POOLS_ERROR = 'USER_POOLS_ERROR'

export const ADD_USER_TO_POOL_REQUEST = 'ADD_USER_TO_POOL_REQUEST'
export const ADD_USER_TO_POOL_SUCCESS = 'ADD_USER_TO_POOL_SUCCESS'
export const ADD_USER_TO_POOL_ERROR = 'ADD_USER_TO_POOL_ERROR'

export const POOL_USERS_REQUEST = 'POOL_USERS_REQUEST'
export const POOL_USERS_SUCCESS = 'POOL_USERS_SUCCESS'
export const POOL_USERS_ERROR = 'POOL_USERS_ERROR'

export const CREATE_POOL_REQUEST = 'CREATE_POOL_REQUEST'
export const CREATE_POOL_SUCCESS = 'CREATE_POOL_SUCCESS'
export const CREATE_POOL_ERROR = 'CREATE_POOL_ERROR'
