export const EMPTY_BRACKET_REQUEST = 'EMPTY_BRACKET_REQUEST'
export const EMPTY_BRACKET_SUCCESS = 'EMPTY_BRACKET_SUCCESS'
export const EMPTY_BRACKET_ERROR = 'EMPTY_BRACKET_ERROR'

export const USER_BRACKET_SAVE_REQUEST = 'USER_BRACKET_SAVE_REQUEST'
export const USER_BRACKET_SAVE_SUCCESS = 'USER_BRACKET_SAVE_SUCCESS'
export const USER_BRACKET_SAVE_ERROR = 'USER_BRACKET_SAVE_ERROR'

export const USER_BRACKET_REQUEST = 'USER_BRACKET_REQUEST'
export const USER_BRACKET_SUCCESS = 'USER_BRACKET_SUCCESS'
export const USER_BRACKET_ERROR = 'USER_BRACKET_ERROR'
